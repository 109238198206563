
import { defineComponent } from 'vue'
import Footer from '@/components/common/Footer.vue'
import { IonPage, IonContent, IonRow, IonCol, IonButton } from '@ionic/vue'

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Footer,
    IonRow,
    IonCol,
    IonButton
  },

  setup() {
    const redirectAndRefresh = () => {
      window.location.href='/dashboard'
    }
    
    return {
      redirectAndRefresh
    }
  },
})
