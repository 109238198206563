
import {computed, defineComponent} from 'vue'
import {
  logoWhatsapp
} from "ionicons/icons";
import {
    IonItem,
    IonText
} from "@ionic/vue";
import { Browser } from '@capacitor/browser'
import {useStore} from "vuex";
import useI18nAffidaty from '@/composable/I18nAffidatyApi';
export default defineComponent({
    components: {
        IonItem,
        IonText
    },
    setup() {
        const store = useStore()
        const { t } = useI18nAffidaty()
        const openInApp = (link: string) => {
            Browser.open({url: link})
        }

        return {
            icons: {
              logoWhatsapp
            },
            year: new Date().getFullYear(),
            openInApp,
            t,
            language: computed(() => store.getters.language)
        }
    },
})
