import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74b9e2a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-footer a-pl-10 a-pr-10" }
const _hoisted_2 = { class: "component-footer__text__desktop" }
const _hoisted_3 = { class: "component-footer__text__mobile" }
const _hoisted_4 = { class: "component-footer__text__desktop" }
const _hoisted_5 = { class: "component-footer__text__mobile" }
const _hoisted_6 = {
  class: "component-footer__link",
  slot: "end",
  color: "dark",
  href: "https://wa.me/+393713856894",
  target: "_blank"
}
const _hoisted_7 = { class: "component-footer__text__desktop" }
const _hoisted_8 = { class: "component-footer__text__mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_item, { lines: "none" }, {
      default: _withCtx(() => [
        (_ctx.language == 'en-US')
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              slot: "end",
              class: "ion-text-right component-footer__link",
              href: "",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.openInApp('https://affidaty.io/affidaty-home2-en/#footer')), ["prevent"]))
            }, [
              _createVNode(_component_ion_text, { color: "dark" }, {
                default: _withCtx(() => [
                  _createTextVNode(" © " + _toDisplayString(_ctx.year) + " Affidaty ", 1)
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              color: "dark",
              slot: "end",
              class: "ion-text-right component-footer__link",
              href: "",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openInApp(' https://affidaty.io/#footer')), ["prevent"]))
            }, [
              _createVNode(_component_ion_text, { color: "dark" }, {
                default: _withCtx(() => [
                  _createTextVNode(" © " + _toDisplayString(_ctx.year) + " Affidaty ", 1)
                ]),
                _: 1
              })
            ])),
        _createVNode(_component_ion_text, {
          slot: "end",
          class: "ion-text-right component-footer__text ion-no-margin"
        }, {
          default: _withCtx(() => [
            _createTextVNode("·")
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          class: "component-footer__link",
          slot: "end",
          href: "",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.openInApp('https://legal.affidaty.io/en/terms-and-conditions-synkrony-exchange')), ["prevent"]))
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_ion_text, { color: "dark" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Terms and conditions')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_ion_text, { color: "dark" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Terms')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_ion_text, {
          slot: "end",
          class: "ion-text-right component-footer__text ion-no-margin"
        }, {
          default: _withCtx(() => [
            _createTextVNode("·")
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          class: "component-footer__link",
          slot: "end",
          color: "dark",
          href: "",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.openInApp('https://legal.affidaty.io/en/privacy-policy-synkrony-exchange')), ["prevent"]))
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_ion_text, { color: "dark" }, {
              default: _withCtx(() => [
                _createTextVNode(" Privacy policy ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_ion_text, { color: "dark" }, {
              default: _withCtx(() => [
                _createTextVNode(" Privacy ")
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_ion_text, {
          slot: "end",
          class: "ion-text-right component-footer__text ion-no-margin"
        }, {
          default: _withCtx(() => [
            _createTextVNode("·")
          ]),
          _: 1
        }),
        _createElementVNode("a", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_ion_text, {
              color: "dark",
              class: "d-flex ion-align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.icons.logoWhatsapp,
                  class: "a-mr-5"
                }, null, 8, ["icon"]),
                _createTextVNode(" Help ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_ion_text, {
              color: "dark",
              class: "d-flex ion-align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.icons.logoWhatsapp
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}